import {Tooltip} from "bootstrap";

export default defineNuxtPlugin(({vueApp}) => {
  vueApp.directive('tooltip', {
    mounted(el, binding) {
      const config: Partial<Tooltip.Options> = {};

      if (binding.value) {
        config.title = binding.value;
      }

      new Tooltip(el, config);
    },
    getSSRProps() {
      return {};
    }
  })
});