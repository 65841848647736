import { default as aboutg3dIqs1tpGMeta } from "/builds/bogutskyy/belarus-border-web/pages/about.vue?macro=true";
import { default as _91code_93n91dMbA4jyMeta } from "/builds/bogutskyy/belarus-border-web/pages/checkpoint/[code].vue?macro=true";
import { default as indexOUxKdHmF5DMeta } from "/builds/bogutskyy/belarus-border-web/pages/index.vue?macro=true";
import { default as _91code_93UzjDf42TnXMeta } from "/builds/bogutskyy/belarus-border-web/pages/tlgrm/checkpoint/[code].vue?macro=true";
import { default as indexJoxb4MsWfPMeta } from "/builds/bogutskyy/belarus-border-web/pages/tlgrm/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/about.vue")
  },
  {
    name: "checkpoint-code",
    path: "/checkpoint/:code()",
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/checkpoint/[code].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/index.vue")
  },
  {
    name: "tlgrm-checkpoint-code",
    path: "/tlgrm/checkpoint/:code()",
    meta: _91code_93UzjDf42TnXMeta || {},
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/tlgrm/checkpoint/[code].vue")
  },
  {
    name: "tlgrm",
    path: "/tlgrm",
    meta: indexJoxb4MsWfPMeta || {},
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/tlgrm/index.vue")
  }
]